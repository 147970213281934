import React from 'react';
import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import { TooltipBox, TooltipTrigger } from '@shoreag/base/Tooltip';
import ProgressStatusBar from './index';

const ProgressStatusBarUi = ({
  baseSx,
  failureSx,
  failureMessage,
  progressMessage,
  progressSx,
  successMessage,
  successSx,
  height,
  ...rest
}) => {
  const { success, failure } = rest;
  const isSuccess = !!success;
  const isFailure = !!failure;
  const radius = height / 2;
  const Bar = ({ sx }) => (
    <Box
      sx={{
        borderBottomLeftRadius: radius,
        borderBottomRightRadius: radius,
        borderTopLeftRadius: radius,
        borderTopRightRadius: radius,
        height,
        width: '100%',
        ...sx,
      }}
    />
  );

  const BarWithTooltip = ({ children, message }) => (
    <TooltipTrigger as="span" data-tip={message} id="statusBartooltip">
      {children}
    </TooltipTrigger>
  );

  const BaseBar = () => (
    <Bar
      sx={{
        bg: 'grays.3',
        ...baseSx,
      }}
    />
  );
  const ProgressBar = () => (
    <BarWithTooltip message={progressMessage}>
      <Bar
        sx={{
          bg: 'accentDark',
          ...progressSx,
        }}
      />
    </BarWithTooltip>
  );

  const SuccessBar = () => (
    <BarWithTooltip message={successMessage}>
      <Bar
        sx={{
          bg: 'success',
          ...(isFailure
            ? {
                borderBottomRightRadius: '',
                borderTopRightRadius: '',
              }
            : {}),
          ...successSx,
        }}
      />
    </BarWithTooltip>
  );
  const FailureBar = () => (
    <BarWithTooltip message={failureMessage}>
      <Bar
        sx={{
          bg: 'error',
          ...(isSuccess
            ? {
                borderBottomLeftRadius: '',
                borderTopLeftRadius: '',
              }
            : {}),
        }}
      />
    </BarWithTooltip>
  );
  return (
    <>
      <ProgressStatusBar
        baseBar={<BaseBar />}
        failureBar={<FailureBar />}
        progressBar={<ProgressBar />}
        successBar={<SuccessBar />}
        {...rest}
      />
      <TooltipBox
        id="statusBartooltip"
        tooltipProps={{
          sx: {
            '&#statusBartooltip': {
              '&.place-top::after': {
                borderTopColor: 'white',
              },
              bg: 'white !important',
              boxShadow: 0,
              color: 'accentSecondary',
            },
          },
        }}
      />
    </>
  );
};

ProgressStatusBarUi.propTypes = {
  baseSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  failureMessage: PropTypes.string,
  failureSx: PropTypes.shape({}),
  height: PropTypes.number,
  message: PropTypes.string.isRequired,
  progressMessage: PropTypes.string,
  progressSx: PropTypes.shape({}),
  successMessage: PropTypes.string,
  successSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

ProgressStatusBarUi.defaultProps = {
  baseSx: {},
  failureMessage: '',
  failureSx: {},
  height: 4,
  progressMessage: '',
  progressSx: {},
  successMessage: '',
  successSx: {},
  sx: {},
};

export default ProgressStatusBarUi;
