import React from 'react';
import PropTypes from 'prop-types';

const ProgressStatusBar = ({
  baseBar,
  progress,
  progressBar,
  success,
  successBar,
  failure,
  failureBar,
  width,
}) => {
  const isProgress = !!(progress && progressBar);
  const isSuccess = !!(success && successBar);
  const isFailure = !!(failure && failureBar);

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        width,
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: 0,
        }}
      >
        {baseBar}
      </div>
      {isProgress && (
        <div
          style={{
            position: 'relative',
            width: `${progress}%`,
          }}
        >
          {progressBar}
        </div>
      )}
      {isSuccess && (
        <div
          style={{
            position: 'relative',
            width: `${success}%`,
          }}
        >
          {successBar}
        </div>
      )}
      {isFailure && (
        <div
          style={{
            position: 'relative',
            width: `${failure}%`,
          }}
        >
          {failureBar}
        </div>
      )}
    </div>
  );
};

ProgressStatusBar.propTypes = {
  baseBar: PropTypes.node,
  failure: PropTypes.number,
  failureBar: PropTypes.node,
  pathStyle: PropTypes.shape({}),
  progress: PropTypes.number,
  progressBar: PropTypes.node,
  success: PropTypes.number,
  successBar: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProgressStatusBar.defaultProps = {
  baseBar: null,
  failure: null,
  failureBar: null,
  pathStyle: {},
  progress: null,
  progressBar: null,
  success: null,
  successBar: null,
  width: '100%',
};

export default ProgressStatusBar;
