import React from 'react';
import Box from '@shoreag/base/Box';
import ProgressStatusBarUi from '../../components/ProgressStatusBar/ui';

const DevelopAComponent = () => {
  return (
    <Box p={80}>
      <ProgressStatusBarUi
        failure={30}
        failureMessage="999 failed"
        height={20}
        progress={0}
        success={50}
        successMessage="999 passed"
      />
      <br />
      <ProgressStatusBarUi height={20} progress={35} progressMessage="35%" />
    </Box>
  );
};

export default DevelopAComponent;
